import { Post } from "./Post";
import { fsPoly } from "./fs-poly/fs";
import { groupBy } from "./groupBy";
import { partition } from "./partition";

const FILTER =
  /(\d{4})-(\d{2})-(\d{2})_(\d{2})-(\d{2})-(\d{2})_UTC(?:_\d+)?\.(?:txt|jpg|mp4)/;

type ProtoPost = [number, string[]];

export class Account {
  fs: fsPoly;
  urlBase: string;
  targetDir: string;

  constructor(fs: fsPoly, urlBase: string, targetDir: string = ".") {
    this.fs = fs;
    this.urlBase = urlBase;
    this.targetDir = targetDir;
  }

  async getPosts(): Promise<Post[]> {
    const TODAY_UTC = new Date(Date.now());
    const LAST_WEEK = Date.UTC(
      TODAY_UTC.getUTCFullYear(),
      TODAY_UTC.getUTCMonth(),
      TODAY_UTC.getUTCDate() - 7
    );

    const allFiles = await this.fs.readdir(this.targetDir, true);
    const protoPosts = selectAllPostsAfter(allFiles, LAST_WEEK);
    const profiles = selectProfilePictures(allFiles);
    return await Promise.all(protoPosts.map(p => this.processPost(p, profiles)));
  }

  /**
   * Maps a ProtoPost into a Post by formatting it correctly and fetching the caption
   * @param {ProtoPost} post The ProtoPost to be transformed 
   * @param {Record<String, String>} profiles A Record of <InstagramHandle, ProfileURLLocation> 
   * @returns {Post} A Post
   */
  private async processPost(post: ProtoPost, profiles: Record<string, string>): Promise<Post> {
    const [time, files] = post;
    const [[ captionFile, ], srcs] = partition(files, f => f.endsWith("txt"));
    
    const caption = !!captionFile ? await this.fs.readFile(captionFile) : "";
    const accountName = srcs[0].split("/")[0];

    return {
      caption,
      srcs: srcs.map(src => this.urlBase + src),
      time,
      page: {
        name: accountName,
        profile: this.urlBase + profiles[accountName]
      }
    }
  }
}

/**
 * Filters a directory listing into a collection of ProtoPost based on a cutoff and the assumption the directory was generated by [Instaloader](https://github.com/instaloader/instaloader) 
 * @param {string[]} posts an array of file names
 * @param {number} cutoff a unix timestamp to that marks the oldest acceptable post  
 * @returns {ProtoPost[]} a collection of [timestamp: number, file names: string[]].
 */
function selectAllPostsAfter(posts: string[], cutoff: number): ProtoPost[] {
  const grouped: Record<number, string[]> = groupBy(posts, p => {
    const m = p.match(FILTER);
    if (!!m) {
      return Date.UTC(
        parseInt(m[1]),
        parseInt(m[2]) - 1, //month is zero indexed
        parseInt(m[3]),
        parseInt(m[4]),
        parseInt(m[5]),
        parseInt(m[6])
      );
    } else {
      //The file did not conform to the listing requirements
      return -1;
    }
  });

  return Object.entries(grouped)
    //map each [unix time stamp, files[]] => [unix timestamp as a number, files[]]
    .map(([t, l]): ProtoPost => [parseInt(t), l])
    //filter out everything that is too old (or also invalid: -1)
    .filter(([t]) => t > cutoff)
    //sort the posts newest first 
    .sort(([t1], [t2]) => t2 - t1);
}

/**
 * Makes a AccountHandle => ProfileFileLocation map
 * @param {string[]} filenames a list of file names to check against, assumpting the directory was generated by [Instaloader](https://github.com/instaloader/instaloader)
 * @returns {Record<string, string>} A string => string Record
 */
function selectProfilePictures(filenames: string[]): Record<string, string> {
  const profiles = filenames.filter((l) => l.includes("profile_pic"));

  return profiles.reduce<Record<string, string>>((acc, cur) => {
    const [key, ] = cur.split("/");
    acc[key] = cur;
    return acc;
  }, {});
}
